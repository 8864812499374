@import "../../Utils/scss/variables";

.navbar {
  //background-color: $maxi-white;
  background-color: transparent;
  height: 12vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.5s all ease;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 999;

  div {
    padding: 2vh 5vh;
  }

  .logo{
    img {
      height: 8vh;
      width: auto;
    }
  }

  .navbar-item {
    text-transform: uppercase;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color:  $maxi-white;
  }

  .navbar-item:hover {
    cursor: pointer;
    // background-color: $maxi-red;
    // color: $maxi-white;
    // border-radius: 30px;
    border-bottom: 2px solid white;
  }

  Link {
    div {
      text-decoration: none !important;
    }
  }
}
.navbar.colorChange {
  background-color: $maxi-white;
  .navbar-item {
    color: $maxi-grey;
  }
  .navbar-item:hover {
    color: $maxi-black;
    border-bottom: 2px solid $maxi-black;
  }
}
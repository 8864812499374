@import "../../Utils/scss/variables";


.project-container {

    .project-intro {
        height: 40vh;
        background-image: url('../../Assests/project-banner.jpg');
        background-size: cover;

        .project-header {

            position: absolute;
            top: 0;
            left: 0; 
            height: 40vh;
            width: 100%;
            background: hsla(0%, 0%, 0%, 0.4);
            backdrop-filter: blur(2px); 

            h2 {
                position: relative;
                width: 70%;
                top: 20vh;
                left: 10vh;
                font-size: 58px;
                color: $maxi-white;
                font: $font-primary;
                font-weight: 800;
                font-style:italic;
                text-align: left;
            }
        }
    }

    .project-catalog {
        height: 90vh;
        background-color: $maxi-white;

        .scroll-arrow {
            background-color: white;
            padding: 1%;
            border-radius: 50%;
            height: 5vh;
            position: absolute;
            top: 7.5vw;
            right: 7.5%;

            transition: background-color 0.5s ease-out;
            animation: bounce 1.5s infinite ease-out;
        }

        .scroll-arrow:hover {
            background-color: rgba(255, 0, 0, 0.6);
            cursor: pointer;
        }
        
        @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateX(0);
            }
            40% {
              transform: translateX(-30px);  
            }
            60% {
              transform: translateX(-15px);
            }   
        }

        .projects {
            background-color: rgba(255, 255, 255, 0.2);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            border: 1px solid rgba(255, 255, 255, 0.18);

            position:absolute;
            margin: 5vw 5vw;
            width: 90vw;
            height: 75vh;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: flex-start;
            background-color:whitesmoke;
            border-radius: 25px;
            overflow: scroll;
            overflow-y: hidden;
            scroll-behavior: smooth;

            .project-item {
                margin: 0% !important;
                padding: 0% !important;
                height: 100% !important;
                width: 22% !important;                

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                .project-details {
                    position: relative;
                    top: -102%;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    display: none;
                }

            }
            .project-item:hover {

                cursor: pointer;
                .project-details {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    background-color: rgba(0, 0, 0, 0.7);
                    height: 105% !important;
                    color: $maxi-white;

                    .project-name {
                        width: 80%;
                        margin: 0 auto;
                        font-size: 22px;
                        color: $maxi-white;
                        font: $font-primary;
                        font-weight: 800;
                        border-bottom: 3px solid $maxi-white;;
                        padding-bottom: 3%;
                    }

                    .project-client {
                        width: 80%;
                        margin: 0 auto;
                        padding-top: 3%;
                        font-size: 18px;
                        color: $maxi-white;
                        font: $font-primary;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.ReactModal__Content {

    .project-modal {
        padding-left: 2%;
        padding-right: 2%;

        display: grid;
        grid-template-columns: 50% 50%;
        height: 100%;

        .project-modal-info {
            padding: 2%;
            .project-name {
                font-size: 32px;
                color: $maxi-grey;
                font: $font-primary;
                font-weight: 800;
                margin-bottom: 1%;
            }
            .project-client {
                font-size: 24px;
                color: $maxi-grey;
                font: $font-primary;
                font-weight: 800;
                margin-bottom: 1%;
            }
            .project-location {
                font-size: 20px;
                color: $maxi-grey;
                font: $font-primary;
                font-weight: 600;
                font-style: italic;
                margin-bottom: 1%;
            }
        }
        .project-carousel {
            padding: 2%;
            overflow: hidden;
            position: relative;

            img, video {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            .left-arrow {
                padding: 2%;
                height: 10%;
                width: auto;
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translateY(-50%);
            }

            .right-arrow {
                padding: 2%;
                height: 10%;
                width: auto;
                position: absolute;
                top: 50%;
                right: 0%;
                transform: translateY(-50%);
            }
            
            .arrow {
                background-color: rgba(255,255, 255, 0.8);
                border-radius: 50%;
            }

            .arrow:hover{
                transition: background-color 0.5s ease-in-out;
                background-color: $maxi-white;
                cursor: pointer;
                border-radius: 50%;
            }
        }
    }
}
@import "../../Utils/scss/variables";
.footer {
  background-color: $maxi-white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: $maxi-white;
  font-family: $font-secondary;
  color: $maxi-black;
}

.footer-col {
  margin: 20px;
  padding: 20px;
  width: 25%;
}

.footer-about-content {
  padding-top: 10px;
  text-align: justify;
}

.footer-links {
  line-height: 1.5em;
  text-align: left;
  // text-decoration: underline;
  a {
    font-family: $font-secondary;
    color: $maxi-black;
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
    }
  }
  a:hover {
    color: $maxi-red;
    text-decoration: underline;
  }
}

.footnote {
  background-color: $maxi-grey;
  color: $maxi-white;
  font-family: $font-secondary;
  font-size: small;
  padding: 1%;
}
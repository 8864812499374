@import "../../Utils/scss/variables";

.product-container {

    .product-intro {
        height: 40vh;
        background-image: url('../../Assests/product-banner.jpeg');
        background-size: cover;

        .product-header {

            position: absolute;
            top: 0;
            left: 0; 
            height: 40vh;
            width: 100%;
            background: hsla(0%, 0%, 0%, 0.4);
            backdrop-filter: blur(2px); 

            h2 {
                position: relative;
                width: 70%;
                top: 20vh;
                left: 10vh;
                font-size: 58px;
                color: $maxi-white;
                font: $font-primary;
                font-weight: 800;
                font-style:italic;
                text-align: left;
            }
        }
    }

    .product-catalog {
        height: 90vh;
        background-color: $maxi-white;

        .products {
            background-color: rgba(255, 255, 255, 0.2);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            border: 1px solid rgba(255, 255, 255, 0.18);

            position:absolute;
            margin: 5vw 5vw;
            width: 90vw;
            height: 75vh;
            display: grid;
            grid-template-columns: 20% 80%;
            background-color:whitesmoke;
            border-radius: 25px;

            .nav-bar {
                padding-top: 5vh;
                display: flex;
                flex-direction: column;
                overflow: scroll;
                scroll-behavior: smooth;

                button {
                    margin: 1vh auto;
                    width: 75% !important;
                    font-family: $font-secondary;
                    margin-top: 5px;
                    background: none;
                    color: $maxi-black !important;
                    font-size: 0.75em;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding: 2vh;
                    border: 2px solid $maxi-black ;
                    border-radius: 25px;
                }
                button:hover {
                    transition: 0.5s;
                    cursor: pointer;
                    color: $maxi-white !important;
                    background-color: $maxi-red;
                    border: 2px solid $maxi-white ;
                }
                .selected-button {
                    transition: 0.5s;
                    cursor: pointer;
                    color: $maxi-white !important;
                    background-color: $maxi-red;
                    border: 2px solid $maxi-white;
                }

                .subcategory {
                    padding-bottom: 1vh ;
                    button {
                        margin: auto 0.1vh !important;
                        width: 50% !important;
                        height: 50% !important;
                        font-family: $font-secondary;
                        background: none;
                        color: $maxi-black !important;
                        font-size: 0.75em;
                        font-weight: bold;
                        text-transform: uppercase;
                        padding: 0.5vh !important;
                        border: 1px solid $maxi-black ;
                        border-radius: 10px;
                    }
                    button:hover {
                        transition: 0.5s;
                        cursor: pointer;
                        color: $maxi-white !important;
                        background-color: $maxi-red;
                        border: 2px solid $maxi-white ;
                    }
                    .selected-button {
                        transition: 0.5s;
                        cursor: pointer;
                        color: $maxi-white !important;
                        background-color: $maxi-red;
                        border: 2px solid $maxi-white;
                    }
                }

            }

            .product-list {
                display: flex;
                flex-direction: row;
                align-content: flex-start;
                flex-wrap: wrap;
                scroll-behavior: smooth;
                overflow: scroll;
                padding-top: 2%;

                .product-search {
                    height: 40px;
                    width: 100%;
                    border-radius: 25px;
                    margin-bottom: 10px;
                    
                    input {
                        height: 80%;
                        width: 80%;
                        margin: auto;
                        border-radius: 25px;
                        padding-left: 10px;
                        border: 1px solid $maxi-grey;
                        transition: 0.25s;
                    }
                    input:focus {
                        
                        outline: 1px solid $maxi-red;
                        border: 1px solid $maxi-red;
                    }
                }

            }
        }
    }
}
@import "../../Utils/scss/variables";

.home {
  position: relative;
  user-select: none;
  margin: 0vh 0vh;

  //background-color: cornflowerblue ;

  .intro {
    position: relative;
    height: 100vh;

    #video {
      z-index: -1;
      width: 100%;
      height: 100vh;
      object-fit: cover;

    }
    .video-overlay {
      z-index:1;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.4);
    }

    .intro-content {
      z-index: 2;
      position: absolute;
      top: 50%;
      width: 50% !important;
      // left: 50%;
      transform: translate(15%, -50%);

      color: $maxi-white;
      text-align: left;
      font-size: 1.5em;
      font-family: $font-primary;
      width: 80%;
      
      h1, h2 {
        font-weight: 400;
        font-style: normal;
      }

      p {
        font-family: $font-secondary;
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
      }

      button {
        font-family: $font-secondary;
        margin-top: 5vh;
        background: none;
        color: $maxi-white !important;
        font-size: 0.65em;
        font-weight: bold;
        text-transform: uppercase;
        padding: 2vh 5vh;
        border: 2px solid $maxi-white ;
        border-radius: 25px;
      }
      button:hover {
        transition: 0.5s;
        cursor: pointer;
        background-color: $maxi-white;
        color: $maxi-black !important;
      }
    }
  }

  .fold2 {
    height: $fold-height;
    background-color: $maxi-white;
  }

  .fold3 {
    height: $fold-height;
    background-color: $maxi-grey;

    .offerings-header {
      padding: 5vh 0vh;
      font-family: $font-secondary;
      font-weight: bold;
      font-size: 36px;
      color: $maxi-white;
      text-transform: uppercase;
    }

    .offerings-container {
      display: grid;
      margin: 0vh 20vh;
      grid-template-columns: 25% 25% 25% 25% ;
      justify-content: center;
    }

    .offerings-item {
      margin: 3vh;

      img {
        height: 10vh;
        width: 10vh;
        padding: 10%;
        background-color: $maxi-red;
        border-radius: 50%;
        transition: 0.5s ease-in-out;
        
      }

      img:hover {
        cursor: pointer;
        // background-color: aqua;
        background-color: var(--c);
      }

      p {
        font-family: $font-secondary;
        font-weight: bold;
        color: $maxi-white;
        font-size: large;
        user-select: none;
      }
    }

    button {
      font-family: $font-secondary;
      margin-top: 2vh;
      background: none;
      color: $maxi-white !important;
      font-size: 1em;
      font-weight: bold;
      text-transform: uppercase;
      padding: 2vh 5vh;
      border: 2px solid $maxi-white ;
      border-radius: 25px;
    }
    button:hover {
      transition: 0.5s;
      cursor: pointer;
      background-color: $maxi-white;
      color: $maxi-black !important;
    }
  }

  .fold4 {
    height: $fold-height;
    background-color: $maxi-white;

    .why-header {
      padding: 5vh 0vh;
      font-family: $font-secondary;
      font-weight: bold;
      font-size: 36px;
      color: $maxi-grey;
      text-transform: uppercase;
    }

    .why-container {
      font-family: $font-secondary;
      display: grid;
      margin: 0vh 10vh;
      grid-template-columns: 30% 30% 30% ;
      justify-content: space-around;
    }

    .why-item{
      margin-top: 5vh;
      height: 20vh;
      background: hsla(0, 0%, 100%, 0.8); 
      border: 1px solid rgba(209, 213, 219);  
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);  
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border-radius: 25px;
      transition: 1s ease-in-out;
      color: $maxi-grey;
      

      h4 {
        margin-bottom: 0;
      }
      
      .why-item-sub {
        display: grid;
        grid-template-columns: 30% 70%;
        padding: 5% 10%;
        img {
          width: 100%;
          margin: auto;
        }

        div {
          margin: auto 0;
          p {
              padding-left: 10%;
              text-align: left;
          }
        }
      }
    }

    .why-item:hover {
      color: $maxi-black;
      scale: 1.05;
      background: hsla(0, 100%, 64%, 0.6);
      border: 1px solid rgba(255, 31, 31, 0.1);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
    }

    button {
      font-family: $font-secondary;
      margin-top: 7vh;
      background: none;
      color: $maxi-black !important;
      font-size: 1em;
      font-weight: bold;
      text-transform: uppercase;
      padding: 2vh 5vh;
      border: 2px solid $maxi-black ;
      border-radius: 25px;
    }
    button:hover {
      transition: 0.5s;
      cursor: pointer;
      color: $maxi-white !important;
      background-color: $maxi-red;
      border: 2px solid $maxi-white ;
      // color: $maxi-black !important;
    }
  }

  .fold5 {
    height: $fold-height;
    background-color: $maxi-grey;
    overflow: hidden;

    color: $maxi-white !important;

    .india-map {
      width: auto;
      margin: auto;
      >img:hover {
        transform: none !important;
      }
    }

  }

  .two-column {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .column {
      width: 48%;
      overflow: hidden; 

      .text-container {
        padding: 0% 10%;
        position: relative;
        top: 50%;
        transform: translate(0%, -50%);
      }

      img {
        height: 100%;
        width: 100%;
        transition: transform 1s ease-in-out;
      }
      >img:hover {
        transform: scale(1.2);
      }
      
      h1, h2, h3 {
        text-align: left;
        font-family: $font-primary;
        font-weight: 400;
        font-style: normal;
      }
    }
  }

  // .typed-text {
  //   /* color: orange; */
  //   background-color: rgb(253, 60, 60);
  //   text-transform: uppercase;
  // }

  // .cursor{
  //   position: relative;
  //   margin-left: 1px;
  // }

  // .cursor::after{
  //   content: "";
  //   width: 1px;
  //   height: 90%;
  //   /* border-right: 7px solid orange; */
  //   border-right: 7px solid $maxi-red;
  //   animation: blink 0.7s infinite ease;
  // }

  // @keyframes blink{
  //   0%{
  //     opacity: 0;
  //   }
  //   100%{
  //     opacity: 1;
  //   }
  // }

}
@import "../../Utils/scss/variables";


.product-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: box-shadow 0.3s ease-in-out;

    height: 128px;
    width: 20%;
    margin: 1% 2.5%;
    background-color: $maxi-white;
    border-radius: 25px;
    overflow: hidden;
    position: relative;

    .product-image {
        img {
            height: 75px;
            width: 100%;
            object-fit: cover;
        }
    }

    .product-header {
        font: $font-secondary;
        font-weight: 600;
        text-align: left;
        text-transform: uppercase;
        padding-left: 5%;
        margin-top: 2%;
        font-size: 12px;
        width: 70%;
    }

    .next {
        position: absolute;
        bottom: 10px;
        right: 10px;
        img {
            height: 25px;
            border-radius: 50%;
        }
        img:hover{
            background-color: #ff8a8a;
            transition: 0.25s;
            cursor: pointer;
        }
    }
}

.product-card:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

.ReactModal__Content {
    .product-modal {
        height: 100%;
        display: grid;
        grid-template-rows: 85% 15%;

        .product-details {
            overflow: scroll;
        }

        .product-enquire {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: auto 0;
            img {
                margin: 0 10px;
                height: 40px;
            }
        }
    }
}
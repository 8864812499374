@import "../../Utils/scss/variables";


.about-container {

    .about-intro {
        height: 40vh;
        background-image: url('../../Assests/aboutus-banner.jpg');
        background-size: cover;
        
        .about-header {

            position: absolute;
            top: 0;
            left: 0; 
            height: 40vh;
            width: 100%;
            background: hsla(0%, 0%, 0%, 0.4);
            backdrop-filter: blur(2px); 

            h2 {
                position: relative;
                width: 70%;
                top: 20vh;
                left: 10vh;
                font-size: 58px;
                color: $maxi-white;
                font: $font-primary;
                font-weight: 800;
                font-style:italic;
                text-align: left;
            }
        }
    }

    .about-content {
        background-color: whitesmoke;

        font-family: $font-primary;
        font-weight: 400;
        font-style: normal;
        text-align: left;
        font-size: 20px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10%;
        }

        .about-fold  {
            padding: 5%;
            display: grid;
            grid-template-columns: 50% 50%;
            div {
                margin: auto;
                padding: 5%;
            }
        }
    }
}